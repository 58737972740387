/** @format */

.input_footer input,
.input_footer textarea {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #101828;
  color: #ffffff;
  font-size: 16px;
  margin-top: 20px;
}

.input_footer input#firstName,
.input_footer input#email {
  background-color: #101828;
}

.error-message {
  color: red;
  font-size: 15px;
  font-weight: 400;
  padding-top: 6px;
}

.footer_button {
  padding: 10px 20px;
  background-color: #226DB4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}

.footer_button:hover {
  background-color: #226DB4;
}

.custom_number_container_footer .react-tel-input .form-control {
  background-color: #101828;
  color: #ffffff;
  height: 50px;
  margin-top: 40px;
}

.custom_number_container_footer .react-tel-input .form-control:focus {
  border-color: #ccc;
  margin-bottom: 20px;
}
.footer {
  width: 100%;
}

.mobilephone input {
  width: 100% !important;
}
.mobilephone {
  margin-top: 20px;
}
.mobilephone .selected-flag {
  background-color: #101828;
}
.mobilephone .country-name {
  color: #101828;
}
.message_footer {
  height: 80px !important;
  font-family: 'Inter', sans-serif
}
.footer_values::placeholder {
  color: white;
  font-family: 'Inter', sans-serif
}
.message_footer::placeholder {
  color: white;
  font-family: 'Inter', sans-serif
}
