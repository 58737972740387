.nav_area {
  position: sticky;
  top: 0;
  z-index: 3;
  transition: background-color 0.4s ease, box-shadow 0.5s ease;
}

.nav_area_home {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  box-shadow: #000000;
}

.nav_area_common {
  background-color: #fff;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
  align-items: center;
}

.nav_container_right .nav_container_right_inner,
.nav_container_left,
.nav_container_right {
  display: flex;
  align-items: center;
  gap: 25px;
}
.nav_container_right {
  gap: 75px;
}
.withsubmenunav {
  position: relative;
  cursor: pointer;
}
.nav_subulweb {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 50px;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000001a;
  transition: 0.8s;
  padding: 20px;
  list-style: none;
}
.withsubmenunav:hover .nav_subulweb {
  opacity: 1;
  top: 35px;
  visibility: visible;
}
.mobile_subnav {
  list-style: none;
  padding: 20px;
}
.nav_subul li {
  padding: 10px 0;
}
.nav_subul li a {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}
.withsubmenunav .mainspan {
  position: relative;
  padding-right: 14px;
}
.hidesubmenu {
  display: none;
}
.nav_subul li.header-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}
.ResourcesDropdown {
  display: flex;
  gap: 30px;
}
.currentPage {
  color: var(--primary-Color, #226db4);
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-Color, #226db4);
}

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  right: 12px;
  top: 15px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

/* .bm-cross {
    background: #bdc3c7;
} */

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

/* General sidebar styles */

.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding: 26px 5px;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */

.bm-item {
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 18px;
  font-weight: 500;
}

.bm-burger-button .bm-burger-bars,
.bm-cross-button .bm-cross,
.bm-burger-button {
  display: none;
}

.navbar_btn {
  display: flex;
  gap: 20px;
}

.nav_area.onscroll_bg {
  background-color: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.nav_area_blog {
  box-shadow: 0 0 1px 0px rgb(0 0 0 / 50%);
}

@media only screen and (max-width: 925px) {
  .bm-burger-button {
    display: block;
  }

  .navbar_desktop,
  .navbar_desktop .navbar_btn {
    display: none;
  }

  .navbar_mobile {
    margin-bottom: 20px;
  }
  .mobile_subnav .navbar_mobile {
    margin-bottom: 0;
  }

  .navbar_btn {
    flex-direction: column;
  }

  .nav_container {
    padding: 13px 40px 13px 0;
  }

  .nav_logo {
    height: 24px;
    width: auto;
  }

  .mobile_nav_btn {
    padding: 5px 10px;
  }

  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 22px;
  }
}

@media only screen and (max-width: 367px) {
  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 17px;
  }
  .nav_container {
    padding: 13px 40px 13px 0;
  }
}
