/** @format */

.footer_area {
  background: var(--text-primary-Color, #101828);
}
.footer_container {
  width: 100%;
  color: #eaecf0;
  padding: 64px 0px 48px 0px;
}
.footer_content_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 64px;
  flex-wrap: wrap;
  gap: 20px;
}

.footer_heading {
  color: var(--white-text-color, #fff);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
}

.footer_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.bold-text.footer_text {
  font-weight: 600;
}
.bottom-space.footer_text {
  padding-bottom: 3px;
}
.footer_link {
  cursor: pointer;
}

.footer_nav_area {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 233px;
}

.footer_demo_text {
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.footer_inner_container {
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.footer_demo_btn_container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.footer_contact_area {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 294px;
}
.footer_contact_area > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer_contact_area .footer_contact_area_inner {
  gap: 24px;
}

.social_media_container {
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 940px) {
  .footer_content_container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 568px) {
  .footer_demo_text {
    font-size: 25px;
  }
  .footer_container {
    padding: 24px 0px 24px 0px;
  }
  .footer_nav_area {
    max-width: fit-content;
    gap: 6px;
  }
  .footer_heading {
    margin-bottom: 10px;
  }
  .footer_content_container {
    padding-bottom: 36px;
  }
  .footer_inner_container {
    gap: 18px;
  }
}

@media only screen and (max-width: 435px) {
  .footer_demo_btn_container {
    flex-direction: column;
    gap: 13px;
  }
}
