:root {
  --primary-Color: #226DB4;
  --text-primary-Color: #101828;
  --text-secondary-Color: #121330;
  --white-text-color: #fff;
  --neutral-800: #170F49;
  --neutral-600: #6F6C90;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-Color: #226DB4;
  }
}

body {
  background-color: #fff;
  color: var(--text-primary-Color, #101828);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:after,
:before {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.demo-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  overflow: hidden;
}

.demo-close-btn {
  position: absolute !important;
  right: 19px;
  top: 5px;
  z-index: 1;
}

.demo-popup {
  min-width: 1000px;
  height: 650px;
}

@media only screen and (max-width:568px) {
  .container {
    padding: 0 16px;
  }

  .demo-popup {
    min-width: 385px;
  }
  .demo-close-btn {
    right: -10px;
    top: -49px;
  }

  .demo-close-btn > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: #fff;
  }
}