/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

:root {
  --primary-Color: #226DB4;
  --text-primary-Color: #101828;
  --text-secondary-Color: #121330;
  --white-text-color: #fff;
  --neutral-800: #170F49;
  --neutral-600: #6F6C90;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-Color: #226DB4;
  }
}

body {
  background-color: #fff;
  color: var(--text-primary-Color, #101828);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:after,
:before {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.demo-popup .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  overflow: hidden;
}

.demo-close-btn {
  position: absolute !important;
  right: 19px;
  top: 5px;
  z-index: 1;
}

.demo-popup {
  min-width: 1000px;
  height: 650px;
}

@media only screen and (max-width:568px) {
  .container {
    padding: 0 16px;
  }

  .demo-popup {
    min-width: 385px;
  }
  .demo-close-btn {
    right: -10px;
    top: -49px;
  }

  .demo-close-btn > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: #fff;
  }
}
/** @format */

.footer_area {
  background: var(--text-primary-Color, #101828);
}
.footer_container {
  width: 100%;
  color: #eaecf0;
  padding: 64px 0px 48px 0px;
}
.footer_content_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 64px;
  flex-wrap: wrap;
  gap: 20px;
}

.footer_heading {
  color: var(--white-text-color, #fff);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
}

.footer_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.bold-text.footer_text {
  font-weight: 600;
}
.bottom-space.footer_text {
  padding-bottom: 3px;
}
.footer_link {
  cursor: pointer;
}

.footer_nav_area {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 233px;
}

.footer_demo_text {
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.footer_inner_container {
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.footer_demo_btn_container {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.footer_contact_area {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 294px;
}
.footer_contact_area > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer_contact_area .footer_contact_area_inner {
  gap: 24px;
}

.social_media_container {
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 940px) {
  .footer_content_container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 568px) {
  .footer_demo_text {
    font-size: 25px;
  }
  .footer_container {
    padding: 24px 0px 24px 0px;
  }
  .footer_nav_area {
    max-width: -moz-fit-content;
    max-width: fit-content;
    gap: 6px;
  }
  .footer_heading {
    margin-bottom: 10px;
  }
  .footer_content_container {
    padding-bottom: 36px;
  }
  .footer_inner_container {
    gap: 18px;
  }
}

@media only screen and (max-width: 435px) {
  .footer_demo_btn_container {
    flex-direction: column;
    gap: 13px;
  }
}

:root {
  --radius: 5px;
  --border-width: 1px;
}

.btn {
  border-radius: var(--radius);
  border: var(--border-width) solid var(--primary-Color, #226db4);
  /* Text lg/Semibold */
  padding: 7px 20px;

  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.btn-common-style {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  cursor: pointer;
}

.btn_bgPrimaryColor {
  border-radius: var(--radius);
  border: var(--border-width) solid var(--primary-Color, #226db4);
  padding: 7px 20px;
  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.btn-primary {
  background: #fff;
  color: var(--primary-Color, #226db4);
}

.btn-primary-primary {
  background: var(--primary-Color, #226db4);
  color: var(--white-text-color, #fff);
}

.btn-primary-primary1 {
  color: rgba(172, 66, 255, 1);
}
.btn-primary1 {
  background: rgba(172, 66, 255, 1);
  color: #fff;
  border: 1px solid #fff;
}

.btn-primary-primary2 {
  background: #fff;
  color: #226db4;
  border: var(--border-width) solid #226db4;
}
.btn-primary-primary3 {
  background: transparent;
  color: #fff;
  border: var(--border-width) solid #fff;
}

.btn_navbar_padding {
  padding: 7px 12px;
}

.btn:before,
.btn_bgPrimaryColor:before {
  border-radius: 0 var(--radius) var(--radius) 0;
  right: 0;
  border-left: none;
}

.btn::after,
.btn_bgPrimaryColor:after {
  border-radius: var(--radius) 0px 0px var(--radius);
  left: 0;
  border-right: none;
}

.btn:after,
.btn:before {
  background: var(--primary-Color, #226db4);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: -1;
}

.btn_bgPrimaryColor:after,
.btn_bgPrimaryColor:before {
  background: #fff;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: -1;
}

/* .btn-primary:focus, */
.btn-primary:active,
.not-loading.btn-primary:hover {
  background: var(--primary-Color, #226db4);
  box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  color: #fff;
  transform: translateY(-5px);
}

/* .btn-primary-primary:focus, */
.btn-primary-primary:active,
.not-loading.btn-primary-primary:hover {
  background: #fff;
  box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  color: var(--primary-Color, #226db4);
  transform: translateY(-5px);
}

.not-loading.btn:hover:after,
.not-loading.btn:hover:before,
.not-loading.btn_bgPrimaryColor:hover:after,
.not-loading.btn_bgPrimaryColor:hover:before {
  width: 50%;
}

.btn_common {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.fullwidth {
  width: 100%;
}

@media only screen and (max-width: 568px) {
  .btn-common-style {
    font-size: 16px;
  }
  .btn_navbar_padding {
    padding: 4px 12px;
  }
}

/** @format */

.input_footer input,
.input_footer textarea {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #101828;
  color: #ffffff;
  font-size: 16px;
  margin-top: 20px;
}

.input_footer input#firstName,
.input_footer input#email {
  background-color: #101828;
}

.error-message {
  color: red;
  font-size: 15px;
  font-weight: 400;
  padding-top: 6px;
}

.footer_button {
  padding: 10px 20px;
  background-color: #226DB4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}

.footer_button:hover {
  background-color: #226DB4;
}

.custom_number_container_footer .react-tel-input .form-control {
  background-color: #101828;
  color: #ffffff;
  height: 50px;
  margin-top: 40px;
}

.custom_number_container_footer .react-tel-input .form-control:focus {
  border-color: #ccc;
  margin-bottom: 20px;
}
.footer {
  width: 100%;
}

.mobilephone input {
  width: 100% !important;
}
.mobilephone {
  margin-top: 20px;
}
.mobilephone .selected-flag {
  background-color: #101828;
}
.mobilephone .country-name {
  color: #101828;
}
.message_footer {
  height: 80px !important;
  font-family: 'Inter', sans-serif
}
.footer_values::placeholder {
  color: white;
  font-family: 'Inter', sans-serif
}
.message_footer::placeholder {
  color: white;
  font-family: 'Inter', sans-serif
}

.nav_area {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
  transition: background-color 0.4s ease, box-shadow 0.5s ease;
}

.nav_area_home {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  box-shadow: #000000;
}

.nav_area_common {
  background-color: #fff;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
  align-items: center;
}

.nav_container_right .nav_container_right_inner,
.nav_container_left,
.nav_container_right {
  display: flex;
  align-items: center;
  gap: 25px;
}
.nav_container_right {
  gap: 75px;
}
.withsubmenunav {
  position: relative;
  cursor: pointer;
}
.nav_subulweb {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 50px;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000001a;
  transition: 0.8s;
  padding: 20px;
  list-style: none;
}
.withsubmenunav:hover .nav_subulweb {
  opacity: 1;
  top: 35px;
  visibility: visible;
}
.mobile_subnav {
  list-style: none;
  padding: 20px;
}
.nav_subul li {
  padding: 10px 0;
}
.nav_subul li a {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}
.withsubmenunav .mainspan {
  position: relative;
  padding-right: 14px;
}
.hidesubmenu {
  display: none;
}
.nav_subul li.header-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}
.ResourcesDropdown {
  display: flex;
  gap: 30px;
}
.currentPage {
  color: var(--primary-Color, #226db4);
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-Color, #226db4);
}

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 35px;
  right: 12px;
  top: 15px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

/* .bm-cross {
    background: #bdc3c7;
} */

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

/* General sidebar styles */

.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding: 26px 5px;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */

.bm-item {
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  font-size: 18px;
  font-weight: 500;
}

.bm-burger-button .bm-burger-bars,
.bm-cross-button .bm-cross,
.bm-burger-button {
  display: none;
}

.navbar_btn {
  display: flex;
  gap: 20px;
}

.nav_area.onscroll_bg {
  background-color: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.nav_area_blog {
  box-shadow: 0 0 1px 0px rgb(0 0 0 / 50%);
}

@media only screen and (max-width: 925px) {
  .bm-burger-button {
    display: block;
  }

  .navbar_desktop,
  .navbar_desktop .navbar_btn {
    display: none;
  }

  .navbar_mobile {
    margin-bottom: 20px;
  }
  .mobile_subnav .navbar_mobile {
    margin-bottom: 0;
  }

  .navbar_btn {
    flex-direction: column;
  }

  .nav_container {
    padding: 13px 40px 13px 0;
  }

  .nav_logo {
    height: 24px;
    width: auto;
  }

  .mobile_nav_btn {
    padding: 5px 10px;
  }

  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 22px;
  }
}

@media only screen and (max-width: 367px) {
  .nav_container_right .nav_container_right_inner,
  .nav_container_left {
    gap: 17px;
  }
  .nav_container {
    padding: 13px 40px 13px 0;
  }
}

